/* ---------- estilos del step dos confirmar ----------*/
.container_center {
  display: flex;
  justify-content: center;

  .invalid_data {
    color: red;
    font-size: 12px;
  }

  .valid_data {
    font-size: 12px;
  }
}
.row_container {
  margin-top: 20px;
  min-height: 600px;
  width: 800px;
  max-width: 800px;
}
.container_button {
  display: flex;
  justify-content: center;
  .button_finish {
    width: 50%;
  }
}

// .div_container_card {
//   min-height: 60px;
//   background-color: #f4f6f6;
//   border-radius: 10px;
//   padding: 20px;
//   display: flex;

//   p {
//     font-weight: 700;
//     margin: auto;
//     font-size: 20px;
//   }
// }

.div_form {
  padding: 0px 60px;
  //background-color: yellow;
}

.div_form label {
  display: block;
  color: #7d8994;
  margin-bottom: 10px;
  font-size: 17px;
}

.span_debt {
  margin-top: 20px;
  font-size: 24px;
  //margin-left: 20px;
  padding: 2px;
  background-color: #e9ecef;
}

@media only screen and (max-width: 1100px) {
  .row_container {
    //min-height: 100%;
    width: 100%;
    // min-height: 100%;
  }

  .div_form {
    padding: 0px;
  }
}

///////estilos step finalizar//////
.row_end_payment {
  //background-color: yellow;
  p {
    font-weight: bold;
    font-size: 20px;
    //color: #e0e0e0;
    margin-bottom: 0.5em;
  }
  span {
    color: #a6acaf;
    //font-weight: bold;
    font-size: 20px;
    //text-transform: uppercase;
  }
}

.end_payment {
  border-top: 5px solid #239b56;
  margin-top: 20px;
  font-size: 16px;
  margin-bottom: 15px;
}

.end_payment_rejected {
  border-top: 5px solid #ff5733;
  margin-top: 20px;
  font-size: 16px;
  margin-bottom: 15px;
}

.input_code_size {
  width: 40%;
}

@media only screen and (max-width: 800px) {
  .row_end_payment {
    p {
      font-weight: bold;
      font-size: 16px;
    }
    span {
      color: #a6acaf;
      //font-weight: bold;
      font-size: 15px;
      //text-transform: uppercase;
    }
  }

  .input_code_size {
    width: 100%;
  }
}

.box {
  background: #ddd;
  border-radius: 50%;
  width: 100px;
}

.close_x {
  stroke: black;
  fill: transparent;
  stroke-linecap: round;
  stroke-width: 6;
}

.pricing_card {
  text-align: center;
  &.pricing_card_success {
    border-top: 5px solid rgb(46, 204, 113);
  }
}

.circle {
  fill-opacity: 0;
  stroke: #219a00;
  stroke-width: 16px;
  transform-origin: center;
  transform: scale(0);
  animation: grow 1s cubic-bezier(0.25, 0.25, 0.25, 1.25) forwards;
}

@keyframes grow {
  60% {
    transform: scale(0.8);
    stroke-width: 4px;
    fill-opacity: 0;
  }
  100% {
    transform: scale(0.9);
    stroke-width: 8px;
    fill-opacity: 1;
    fill: #219a00;
  }
}

@keyframes draw {
  100% {
    stroke-dashoffset: 0;
  }
}

.svg_container {
  height: 70px;
  margin-bottom: 30px;
}
