.container {
    width: 100%;
    margin-top: 35px;
    .container_row {
        width: 100%;
        padding-bottom: 56%;
    }
    .container_chat_iframe {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: #f6f7fb;
        border-radius: 15px;
        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        padding-top: 5px;
        padding-bottom: 5px;
        filter: blur(8px);
        -webkit-filter: blur(8px);
    }
    .live {
        z-index: 1;
        width: max-content;
        position: absolute;
        margin: 5px;
        padding: 5px 5px;
        top: 0;
        left: 0;
        right: auto;
        bottom: auto;
        border-radius: 6px;
        font-size: 0.75em;
        margin-right: 6px;
        background-color: #de442b;
        color: white;
        text-align: center;
        font-size: 12px;
        animation: live 2s infinite;
    }
    .message_payment {
        position: absolute;
        padding-top: 8%;
        top: 0;
        right: 0;
        left: 0;
        margin-left: 20%;
        margin-right: 20%;
        .cardStyle {
            background-color: transparent;
            p {
                font-size: 22px;
                font-weight: 600;
            }
            .save_btn {
                background: linear-gradient(to bottom, #608ce4 20%, #265ecb 100%);
                border-radius: 6px;
                border: 1px solid #84bbf3;
                display: inline-block;
                cursor: pointer;
                color: #ffffff;
                font-size: 18px;
                font-weight: bold;
                padding: 15px 41px;
                text-decoration: none;
            }
        }
        @media screen and (max-width: 460px) {
            margin-left: 5%;
            margin-right: 5%;
            .cardStyle {
                p {
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
    }
    @media screen and (min-width: 1500px) {
        .container_row {
            padding-bottom: 650px;
        }
        .container_chat_iframe {
            width: 1200px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .live {
            display: none;
        }
    }
}
