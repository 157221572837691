.container{
    width: 100%;
    margin-top: 35px;
    padding: 0;
    .container_row {
        width: 100%;
        height: 550px;
    }
    .container_chat_iframe{
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #f6f7fb;
        border-radius: 15px;
        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    @media screen and (min-width: 1500px) {
        .container_chat_iframe{
            width: 1200px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
