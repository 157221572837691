.container_overlay {
    display: block;
}
.image {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: contain;
}
.status_bar {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 4px;
    background-color: rgb(250, 83, 108);
    z-index: 1;
}

.label_time {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 3px;
    background-color: rgba(0, 0, 0, 0.6);
    span {
        color: white;
    }
}

.actual_item {
    position: absolute;
    top: 50%;
    bottom: 50;
    left: 0;
    margin-top: -20px;
    margin-left: -10px;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #fcab00;
    -webkit-animation-name: bounce;
    -moz-animation-name: bounce;
    animation-name: bounce;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
@-webkit-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateX(0);
    }
    60% {
        -webkit-transform: translateX(15px);
    }
}

@-moz-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateX(0);
    }
    60% {
        -webkit-transform: translateX(-15px);
    }
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateX(0);
    }
    60% {
        transform: translateX(15px);
    }
}
