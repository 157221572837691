$greenColor: #53bb74;
$yellowColor: #ebc85d;
$redWarnColor: #e5554e;

.countdown_label {
    color: #65584c;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
    letter-spacing: 2px;
    margin-top: 9px;
}
.countdown {
    box-shadow: 0 1px 2px 0 rgba(1, 1, 1, 0.4);
    width: 240px;
    text-align: center;
    background: #f1f1f1;
    border-radius: 5px;
    margin: auto;
    .tiles {
        color: #fff;
        position: relative;
        z-index: 1;
        text-shadow: 1px 1px 0px #ccc;
        display: inline-block;
        font-family: Arial, sans-serif;
        text-align: center;
        padding: 20px;
        border-radius: 5px 5px 0 0;
        font-size: 48px;
        font-weight: thin;
        display: block;
        span {
            width: 70px;
            max-width: 70px;
            padding: 18px 0;
            position: relative;
        }
        .labels {
            width: 100%;
            height: 25px;
            text-align: center;
            position: absolute;
            bottom: 8px;
            li {
                width: 102px;
                font: bold 15px "Droid Sans", Arial, sans-serif;
                color: #f47321;
                text-shadow: 1px 1px 0px #000;
                text-align: center;
                text-transform: uppercase;
                display: inline-block;
            }
        }
    }
}

.color_full {
    background: #53bb74;
}
.color_half {
    background: #ebc85d;
}
.color_empty {
    background: #e5554e;
}
