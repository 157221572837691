.btn_secondary
  background-color: #e7e2e2
  border-color: #e7e2e2
  color: #646777
  
.pricing_card
  text-align: center
  &.pricing_card_warning
    border-top: 5px solid #f6da6e

.pricing_card__body
  padding: 45px 40px

.card__title
  margin-bottom: 30px
  text-transform: uppercase
  position: relative
  &:not(:first-child)
    margin-top: 40px
  .subhead
    text-transform: none
    font-size: 12px
    line-height: 18px
    opacity: 0.7
    margin-top: 3px
  *
    margin-bottom: 0
  h5
    font-size: 13px