.container
  width: 100%
  margin: 20px auto 40px

.playlist_container
  display: flex
  justify-content: center
  padding: 20px
  flex: 1
  flex-wrap: wrap
  color: #333333


