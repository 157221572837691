.input_form_container
  width: 100%
  display: inline-flex
  flex-wrap: wrap
  text-align: left
  margin-top: 12px

.label_input_form
  color: #7283a5
  font-size: 16px
  width: 100%
  line-height: 20px
  display: block
  margin-bottom: 5px

.input_form
  background: #ecf0f1
  border-radius: 6px
  line-height: 40px
  font-size: 20px
  padding: 0px 20px
  width: 100%

.input_short_form_container
  width: 30%
  display: inline-flex
  flex-wrap: wrap
  text-align: left
  margin-top: 12px