@font-face {
  font-family: 'dashboard-ipn';
  src: url('./fonts/dashboard-ipn.eot?4l7rzl');
  src: url('./fonts/dashboard-ipn.eot?4l7rzl#iefix') format('embedded-opentype'),
    url('./fonts/dashboard-ipn.ttf?4l7rzl') format('truetype'),
    url('./fonts/dashboard-ipn.woff?4l7rzl') format('woff'),
    url('./fonts/dashboard-ipn.svg?4l7rzl#dashboard-ipn') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

.icon {
  font-family: 'dashboard-ipn' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 20px;
  display: inline-flex;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.clock:before {
  content: '\e94e';
}
.calendar:before {
  content: '\e953';
}
.pencil:before {
  content: '\e93e';
}
.folderclose:before {
  content: '\e93f';
}
.folderopen:before {
  content: '\e940';
}
.eye:before {
  content: '\e9ce';
}
.eyeblocked:before {
  content: '\e9d1';
}
.volumeup:before {
  content: '\ea26';
}
.volumemedium:before {
  content: '\ea27';
}
.volumemute:before {
  content: '\ea29';
}
.volumeincrease:before {
  content: '\ea2b';
}
.volumedecrease:before {
  content: '\ea2c';
}
.facebooklinear:before {
  content: '\ea90';
}
.facebook:before {
  content: '\ea91';
}
.instagram:before {
  content: '\ea92';
}
.whatsapp:before {
  content: '\ea93';
}
.dobledotsfilled:before {
  content: '\eaa3';
}
.dobledotsunfilled:before {
  content: '\eaa4';
}
.remove:before {
  content: '\e943';
}
.cake:before {
  content: '\e92e';
}
.email:before {
  content: '\e931';
}
.home:before {
  content: '\e941';
}
.phone:before {
  content: '\e942';
}
.account:before {
  content: '\e900';
}
.alert:before {
  content: '\e901';
}
.comment:before {
  content: '\e902';
}
.add:before {
  content: '\e903';
}
.arrowdown:before {
  content: '\e904';
}
.arrowup:before {
  content: '\e905';
}
.arrowleft:before {
  content: '\e906';
}
.arrowright:before {
  content: '\e907';
}
.attach:before {
  content: '\e908';
}
.screenview:before {
  content: '\e909';
}
.settings:before {
  content: '\e90a';
}
.cached:before {
  content: '\e90b';
}
.unchecked:before {
  content: '\e90c';
}
.checked:before {
  content: '\e90d';
}
.cross:before {
  content: '\e90e';
}
.code:before {
  content: '\e90f';
}
.controlmove:before {
  content: '\e910';
}
.copyright:before {
  content: '\e911';
}
.dashboard:before {
  content: '\e912';
}
.delete:before {
  content: '\e913';
}
.done:before {
  content: '\e914';
}
.drag:before {
  content: '\e915';
}
.error:before {
  content: '\e916';
}
.folderempty:before {
  content: '\e917';
}
.folderFull:before {
  content: '\e918';
}
.closefullscreen:before {
  content: '\e919';
}
.fullscreen:before {
  content: '\e91a';
}
.help:before {
  content: '\e91b';
}
.semiChecked:before {
  content: '\e91c';
}
.info:before {
  content: '\e91d';
}
.down:before {
  content: '\e91e';
}
.left:before {
  content: '\e91f';
}
.right:before {
  content: '\e920';
}
.up:before {
  content: '\e921';
}
.lock:before {
  content: '\e922';
}
.menu:before {
  content: '\e923';
}
.moreh:before {
  content: '\e924';
}
.morev:before {
  content: '\e925';
}
.crash:before {
  content: '\e926';
}
.openArrow:before {
  content: '\e927';
}
.pause:before {
  content: '\e928';
}
.play:before {
  content: '\e929';
}
.power:before {
  content: '\e92a';
}
.radiochecked:before {
  content: '\e92b';
}
.radiounchecked:before {
  content: '\e92c';
}
.reply:before {
  content: '\e92d';
}
.pin:before {
  content: '\e92f';
}
.search:before {
  content: '\e930';
}
.nextArrow:before {
  content: '\e932';
}
.previousArrow:before {
  content: '\e933';
}
.emptystar:before {
  content: '\e934';
}
.halfstar:before {
  content: '\e935';
}
.star:before {
  content: '\e936';
}
.interchangeh:before {
  content: '\e937';
}
.interchangev:before {
  content: '\e938';
}
.dislike:before {
  content: '\e939';
}
.like:before {
  content: '\e93a';
}
.toggle:before {
  content: '\e93b';
}
.justify:before {
  content: '\e93c';
}
.warning:before {
  content: '\e93d';
}
