$bp-tablet: 900px;
$color-title: #7c818e;
$color-primary: #8393ca;
/* ---------- contenedor principal ----------*/



.card_container {
  margin-top: 15px;
  width: 100%;
  border-radius: 15px;
  padding: 30px;
  background-color: white;
  .card_errors_div {
      text-align: center;
      margin-top: 10px;
      p {
          color: red;
          font-size: 16px;
      }
  }
}
.row_title {
  margin-top: 10px;
  margin-bottom: 10px;
  p {
    font-weight: bold;
    margin: auto;
    font-size: 26px;
    cursor: pointer;
    &:hover {
      color: #f6da6e;
    }
  }
}
.row_container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  min-height: 600px;
  width: 100%;
  max-width: 1100px;
  p {
    font-weight: bold;
    margin: auto;
    font-size: 15px;
    cursor: pointer;
    &:hover {
      color: red;
    }
  }
  /* ---------- Formulario ----------*/
  .container_register {
    width: calc(100%);
    margin-top: 0px;
    border-radius: 0 15px 15px 0;
    background-color: rgb(240, 240, 240);

    .invalid_data {
      color: red;
      font-size: 12px;
      margin-top: -20px;
      //background-color: yellow;
    }

    .valid_data {
      font-size: 12px;
      margin-top: -20px;
      //background-color: yellow;
    }
  }

  @media screen and (max-width: 970px) {
    border-radius: 15px;
    background-color: rgb(240, 240, 240);
  }

  .container_button {
    display: flex;
    justify-content: center;
    .button_next {
      width: 50%;
    }
  }

  @media only screen and (max-width: 768px) {
    .container_register {
      margin-top: 30px;
    }
  }

  .div_form {
    padding: 30px 60px;

    label {
      display: block;
      color: #7d8994;
      margin-bottom: 10px;
      font-size: 17px;
    }

    input {
      margin-bottom: 15px;
    }
  }

  @media only screen and (max-width: 1100px) {
    .div_form {
      //background-color: red;
      margin-top: 10px;
      padding: 5px 15px;
      input {
        margin-bottom: 10px;
      }
    }
  }
  @media only screen and (max-width: 330px) {
    .div_form {
      padding: 5px 10px;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .row_container {
    //min-height: 100%;
    width: 100%;
    // min-height: 100%;
  }
}

.col_card_form {
  margin-top: 30px;
}

.title_cards_mobile {
  display: none;
}

.title_cards_desktop {
  display: inline;
}

.buttons_mobile_form {
  display: none;
  margin-bottom: 10px;
}
.buttons_mobile_desk_form {
  display: inline;
}

.empty_component_margin {
  margin-top: 60px;
}

@media screen and (max-width: 970px) {
  .col_card_form {
    margin-top: 10px;
  }

  .buttons_mobile_form {
    display: inline;
  }

  .buttons_mobile_desk_form {
    display: none;
  }

  .title_cards_mobile {
    display: inline;
  }

  .title_cards_desktop {
    display: none;
  }

  .empty_component_margin {
    margin-top: 20px;
  }
}

/* ---------- lista de tarjetas ----------*/
.desktop_list_cards {
  display: inline;
}
.mobile_list_cards {
  display: none;
}

@media screen and (max-width: 970px) {
  .row_container {
    flex-direction: column;
    .c_wallet {
      width: 100%;
      border-radius: 15px 15px 0 0;
      &__payment_list {
        display: contents;
      }
    }
    .container_register {
      width: 100%;
      border-radius: 0 0 15px 15px;
    }
  }
  .desktop_list_cards {
    display: none;
  }
  .mobile_list_cards {
    display: inline;
  }
}

.scroll_cards_list {
  //background-color: yellowgreen;
  overflow-y: scroll;
  height: 600px;
  width: 326px;
}

.scroll_cards_list::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scroll_cards_list::-webkit-scrollbar:vertical {
  width: 11px;
}

.scroll_cards_list::-webkit-scrollbar:horizontal {
  height: 0px;
  overflow: hidden;
}

.scroll_cards_list::-webkit-scrollbar-thumb {
  border-radius: 15px;
  border: 3px solid #f0f0f0;
  /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

///savedcard////
.saved_card_title {
  font-size: 28px;
  color: #7c818e;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

@media screen and (max-width: 970px) {
  .scroll_cards_list {
    height: 100%;
    width: 100%;
    overflow-y: hidden;
  }
  .saved_card_title {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
