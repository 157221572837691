.container {
  position: fixed;
  height: 100%;
  width: 300px;
  padding: 30px;
  background: #ecf0f1;
}
.button_container {
  display: flex;
  justify-content: space-evenly;
}

.info_container {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.course_name {
  color: #143169;
  font-size: 24px;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  word-wrap: break-word;
}

.title {
  color: #265ecb;
  font-size: 16px;
}

.back_page {
  display: flex;
  justify-content: center;
  border: 1px solid #fb9000;
  border-radius: 15px;
  margin: 12px 0;
  color: #fb9000;
}

.arrow_left {
  font-size: 20px;
}

.container_playlist_files {
  > row {
    padding: 0;
  }
}

.scroll_playlist {
  height: calc(100vh - 90px);
  width: 300px;
  overflow-x: hidden;
}

.scroll_playlist::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scroll_playlist::-webkit-scrollbar:vertical {
  width: 5px;
}

.scroll_playlist::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #b2babb;
}

.hide_back {
  display: none;
}

.container_progress {
  height: calc(100% - 160px);
  position: relative;
}

.vertical_center {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 40%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

//mobile styles

.score_attempt_rigth {
  color: #52c41a;
  font-size: 25px;
}

.score_attempt_wrong {
  color: #e74c3c;
  font-size: 25px;
}
