.container_item 
  padding-right: 15px
  padding-left: 15px

.container_item_small
  padding-right: 10px
  padding-left: 10px

.space_item
  margin: 10px 0px 10px 0px
  padding: 10px 0px 10px 0px
  
.thumbnail
  display: flex
  align-items: center
  justify-content: flex-start
  margin: 16px 0
  width: 100%

.poster
  align-items: center
  border-bottom: 3px solid #ffffff
  display: flex
  height: 72px
  overflow: hidden
  position: relative
  width: 100px
  & > img
    width: 100%
    object-fit: contain

.video_name
  padding-left: 16px
  font-size: 15px
  text-align: left

.duration
  color: #4a4a4a
  font-size: 14px

.current_video
  position: absolute
  font-size: 98px
  color: #fca400
  left: -40px

.current
  color: #fca400
  font-weight: bold

.playlist_video
  display: flex
  align-items: center
  justify-content: flex-start
  margin-bottom: 12px
  padding-left: 16px

.video_image
  height: 100%
  margin-right: 12px

.div_img_video
  width: 60px
  height: 30px

.video_title
  font-size: 14px
  font-weight: bold

.video_description
  font-size: 13px

.folder_icon,
.folder_icon_child
  font-size: 26px
  color: #2ecc71
  margin-right: 12px

.container_video_side_bar_selected
  background-color: #dae0e2

.container_video_side_bar
  text-align: left
  cursor: pointer
  word-wrap: break-word
  padding-left: 0
  padding-right: 0

.link_margin_video
  margin-left: 0px

.text_video_item
  cursor: pointer

.row_file
  margin: 0

.file_box_shadow
  margin-bottom: 20px
  height: 85px
  -webkit-box-shadow: -1px 1px 8px 2px rgba(192,194,198,1)
  -moz-box-shadow: -1px 1px 8px 2px rgba(192,194,198,1)
  box-shadow: -1px 1px 8px 2px rgba(192,194,198,1)
  border-radius: 3px
  background-color: #f6f6f6
  cursor: pointer

.overlays_file
  position: relative

.overlay_progress
  display: block
  margin-top: -24px
  margin-bottom: 20px
  bottom: 0
  height: 4px
  background-color: red
  z-index: 1
  border-radius: 10px
  &.red
    background-color: rgb(250, 83, 108)
  &.green
    background-color: rgb(46, 204, 113)
  &.yellow
    background-color: rgb(255, 203, 0)

.overlay_text_progress
  position: absolute
  bottom: 0
  right: 0
  padding: 3px

.actual_item
  position: absolute
  top: 50%
  bottom: 50
  left: 0
  margin-top: -20px
  margin-left: -10px
  border-top: 20px solid transparent
  border-bottom: 20px solid transparent
  border-left: 20px solid #fcab00
  -webkit-animation-name: bounce
  -moz-animation-name: bounce
  animation-name: bounce
  -webkit-animation-duration: 1s
  -moz-animation-duration: 1s
  animation-duration: 1s
  -webkit-animation-iteration-count: infinite
  -moz-animation-iteration-count: infinite
  animation-iteration-count: infinite
  -webkit-animation-fill-mode: both
  animation-fill-mode: both

.file_image_contaner
  text-align: center
  height: 75px
  padding: 3px

.image_file
  object-fit: contain
  width: 100%
  height: auto
  max-width: 100%
  max-height: 100%

.image_quiz
    width: auto
    height: 90px
    margin-top: 0

.file_data
  margin-top: 10px
  //background-color: green
  height: 65px
  font-size: 14px

@-webkit-keyframes bounce 
    0%,
    20%,
    50%,
    80%,
    100% 
        -webkit-transform: translateX(0)
    60% 
        -webkit-transform: translateX(15px)

@-moz-keyframes bounce 
    0%,
    20%,
    50%,
    80%,
    100% 
        -webkit-transform: translateX(0)
    60% 
        -webkit-transform: translateX(-15px)

@keyframes bounce 
    0%,
    20%,
    50%,
    80%,
    100% 
        transform: translateX(0)
    60% 
        transform: translateX(15px)