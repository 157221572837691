.courses_container {
  width: 100%;
  max-width: 600px;
  position: relative;
  display: flex;
  margin-top: 10px;

  div[class*='alert']::before {
    content: '' !important;
  }
}

.course {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  max-width: 100%;
  overflow: hidden;
  width: 100%;
  max-width: 600px;
  height: 100%;
  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
  h2 {
    letter-spacing: 1px;
    margin: 10px 0;
  }
  h6 {
    opacity: 0.6;
    margin: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .course_info {
    padding: 30px;
    position: relative;
    width: 100%;

    .alert:before {
      content: '' !important;
    }
    div[class*='alert']::before {
      content: '' !important;
    }
  }

  .progress_container {
    position: absolute;
    top: 30px;
    right: 30px;
    text-align: right;
    width: 150px;
    .progress {
      background-color: #ddd;
      border-radius: 3px;
      height: 5px;
      width: 100%;
      &::after {
        border-radius: 3px;
        background-color: #2a265f;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 5px;
        width: var(--v);
      }
    }
    .progress_text {
      font-size: 10px;
      opacity: 0.6;
      letter-spacing: 1px;
      color: black;
    }
  }
  .course_preview {
    background-color: #f8d57e;
    color: #fff;
    padding: 30px;
    a {
      color: black;
      display: inline-block;
      font-size: 14px;
      opacity: 0.6;
      margin-top: 30px;
      text-decoration: none;
    }
  }
  .btn {
    background-color: #2a265f;
    border: 0;
    border-radius: 50px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 16px;
    padding: 12px 25px;
    position: absolute;
    bottom: 30px;
    right: 30px;
    letter-spacing: 1px;
  }
}
