.dark,
.light {
  border-radius: 32px;
  font-size: 16px;
  font-weight: 600;
  padding: 8px;
}

.light {
  background: #ffffff;
  border: 1px solid #265ecb;
  color: #265ecb;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
    color: #265ecb;
  }
}
