.save_btn
  border-radius: 20px
  border: 1px solid #265ecb
  background: #265ecb
  padding: 6px
  font-size: 16px
  min-width: 90px
  text-align: center

.container
  margin: 0 auto
  width: 90%

.required_label
  color: #4a4a4a
  font-size: 16px
  margin: 20px auto 0
  text-align: right
  width: 90%
  span
    color: #f25664
