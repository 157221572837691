@import "./motion/motions";
@import "./motion/fade";
@import "./motion/move";
@import "./motion/others";
@import "./motion/slide";
@import "./motion/swing";
@import "./motion/zoom";
.ant-motion-collapse-legacy {
  overflow: hidden;
  &-active {
    transition: height 0.15s $ease-in-out, opacity 0.15s $ease-in-out !important;
  }
}

.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.15s $ease-in-out, opacity 0.15s $ease-in-out !important;
}
