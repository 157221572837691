.page
  width: 90%
  margin: 20px auto

.title
  font-size: 20px
  color: #265ecb
  display: flex
  align-items: center
  justify-content: space-between

.add_account
  background: #143169
  height: 40px
  width: 40px
  border-radius: 40px
  color: #ffffff
  text-align: center
  font-size: 30px
  display: flex
  align-items: center
  justify-content: center

.account
  border-bottom: 1px dashed #7b7a7b
  padding-bottom: 12px
  width: 100%
  label
    color: #7b7a7b
    margin-right: 8px

.row, .label_row
  color: #143169
  display: flex
  align-items: center
  justify-content: space-between
  margin: 20px auto 12px

.label_row
  justify-content: flex-start
  margin: 8px auto

.edit_account
  color: #265ecb
  text-decoration: underline
  width: 80px
  text-align: center
