.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  height: 100vh;
  width: 300px;
  box-shadow: 0 1px 20px 1px rgba(0, 0, 0, 0.3);
  padding-top: 0px;
  transition: transform 0.3s, width 0.3s;
  transform: translateX(calc(-100% - 20px));
  background: $colorBackgroundSidebar;
  a {
    display: block;
  }
  &.sidebar--show {
    transform: translateX(0);
    .sidebar__back {
      display: block;
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }
}

.sidebar__back {
  height: 100%;
  width: calc(100vw + 300px);
  transform: translateX(300px);
  position: absolute;
  display: none;
  background: rgba(0, 0, 0, 0.3);
  border: none;
  transition: all 0.3s;
}

.sidebar__button {
  width: 70px;
  height: 70px;
  display: flex;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: 0.3s;

  &:focus {
    outline: none;
  }

  &:hover {
    background: $colorHoverSidebar;
  }

  &.sidebar__button--desktop {
    display: none;
  }
}

.sidebar__button-icon {
  margin: auto;
  width: 24px;
}

.sidebar__scroll {
  width: 300px;
  margin-top: -15px;
  & > div {
    height: calc(100vh);
  }
  .scrollbar-track {
    &.scrollbar-track-y {
      width: 2px;
      margin-right: 3px;
    }
    &.scrollbar-track-x {
      display: none !important;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
}

.sidebar__content {
  padding: 0px;
  margin: 0px;
  height: 100%;
  overflow: auto;

  & > div:last-child {
    width: 4px !important;
    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }
}

.sidebar__wrapper--desktop {
  display: none;
}

.sidebar__category-new {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  top: -3px;
  display: block;
  margin-left: 5px;
  background: $redColor;
}

@media screen and (min-width: 768px) {
  .sidebar__button {
    &.collapsed {
      display: none;
    }
    &.collapse_invisible {
      visibility: hidden;
    }
  }

  .sidebar {
    transform: translateX(0);

    &.sidebar--no-desktop {
      transform: translateX(calc(-100% - 20px));

      &.sidebar--show {
        transform: translateX(0);
      }
      .sidebar__wrapper--mobile {
        display: block;
      }
    }

    &.sidebar--collapse {
      width: 55px;
      overflow: visible;
      padding-top: 70px;

      .sidebar__scroll,
      .sidebar__content {
        width: 55px;
        overflow: visible !important;
        transition: width 0.3s;
      }
      .scrollbar-track.scrollbar-track-y {
        margin-right: 188px;
      }
    }
  }

  .sidebar__wrapper--mobile {
    display: block;
  }
}

@media screen and (min-width: 992px) {
  .sidebar.sidebar--no-desktop {
    transform: translateX(0);
    display: none;
  }
}
