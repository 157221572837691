.courses_container {
}
.carousel_card {
    height: 150px;
    margin: 10px;
    .live {
        width: max-content;
        position: absolute;
        margin: 5px;
        padding: 5px 13px;
        top: 0;
        right: 0;
        left: auto;
        bottom: auto;
        border-radius: 6px;
        font-size: 0.75em;
        margin-right: 6px;
        background-color: #de442b;
        color: white;
        text-align: center;
        font-size: 12px;
        animation: live 2s infinite;
    }
    @keyframes live {
        from {
            opacity: 1;
        }
        50% {
            opacity: 0.4;
        }
        to {
            opacity: 1;
        }
    }
.selected{
    border: 2px solid #FCA400
}
}

.carousel_container {
    background-color: #f6f7fb;
    border-radius: 10px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    overflow: hidden;
    width: 350px;
    height: 100%;

    @media screen and (max-width: 400px) {
        width: 250px;
    }

    .title {
        font-size: 18px;
        letter-spacing: 1px;
        margin: 10px 0;
        @media screen and (max-width: 400px) {
            font-size: 14px;
        }
    }
    .text {
        font-size: 22px;
        opacity: 0.6;
        margin: 0;
        letter-spacing: 1px;
        text-transform: uppercase;
        @media screen and (max-width: 400px) {
            font-size: 16px;
        }
    }
    .course_info {
        padding: 10px 0px 0 10px;
    }

    .progress_container {
        position: absolute;
        right: 15px;
        bottom: 0;
        text-align: right;
        width: 150px;
        .progress {
            background-color: #ddd;
            border-radius: 3px;
            height: 5px;
            width: 100%;
            &::after {
                border-radius: 3px;
                background-color: #2a265f;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 5px;
                width: 66%;
            }
        }
        .progress_text {
            font-size: 10px;
            opacity: 0.6;
            letter-spacing: 1px;
        }
    }
}
