.aside {
  height: 100%;
  width: 100%;
}

.icon_bar {
  color: #5c84cf;
  height: 28px;
  padding: 0 0px;
  text-align: right;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.icon_indicator {
  position: absolute;
  top: -12px;
  right: 17px;
  margin: -13px;
  color: #ecf0f1;
  font-size: 44px;
}

.row_icon {
  color: #5c84cf;
  font-size: 28px;
}

.student_name {
  text-transform: capitalize;
}

.completed_profile {
  transition: all 0.3s;
  height: 100%;
  align-content: flex-start;
  flex-wrap: wrap;
  background: #143169;
  color: #ffffff;
  padding: 10px 40px;
}

.profile_row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 15px;
  & a {
    color: #ffffff;
  }
}

.profile_prop {
  margin-left: 20px;
}

.data {
  overflow-y: auto;
  background: #ecf0f1;
  height: calc(100vh - 185px);
  padding: 44px 24px 0 24px;
}

.title {
  color: #265ecb;
  font-size: 16px;
  margin: 12px 0;
}

.box_data,
.box_data_financial {
  align-content: center;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px #c5c5c5;
  color: #265ecb;
  display: flex;
  min-height: 95px;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  & p {
    width: 100%;
    margin-bottom: 0px;
    & span {
      margin-top: 10px;
      font-size: 36px;
    }
  }
}

.box_data_financial {
  // height: 145px;
  overflow: hidden;
}

.box_data_block {
  display: flex;
  width: 50%;
  height: 95px;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  & p {
    width: 100%;
  }
  & span {
    font-size: 36px;
  }
}

.box_data_financial_pending {
  height: 50px;
  width: 100%;
  background-color: #ffe9eb;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f25664;
  & span {
    margin-right: 12px;
  }
}

.attendance {
  margin-top: 20px;
  text-align: right;
  color: #7f8789;
}

.bar {
  border: 1px solid #c5c5cc;
  border-radius: 10px;
  height: 10px;
  margin-bottom: 8px;
  width: 100%;
}
.progress {
  background: #fcb400;
  background: -moz-linear-gradient(left, #fcb400 0%, #fb9000 100%);
  background: -webkit-linear-gradient(left, #fcb400 0%, #fb9000 100%);
  background: linear-gradient(to right, #fcb400 0%, #fb9000 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcb400', endColorstr='#fb9000',GradientType=1 );
  border-radius: 10px;
  height: 100%;
}

.icon_arrow_rigth {
  display: inline;
}


.com_pic_face {
  display: hide;
}

.qr_container {
  width: 100%;
  text-align: center;
}
.qr {
  padding: 20px;
  background-color: white;
}

.logo_sidebar {
  height: 50px;
  width: 30px;
}

.pdf_modal {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: lighter;
  color: #007bff;
  img {
    height: 80px;
    width: 80px;
    margin: 0 16px;
  }
}

.pdf_name {
  text-transform: capitalize;
  margin-bottom: 40px;
}

