.save_btn
  border-radius: 20px
  border: 1px solid #265ecb
  background: #265ecb
  padding: 6px
  font-size: 16px
  min-width: 90px
  text-align: center

.required_fields
  color: #4a4a4a
  font-size: 16px
  margin-top: -18px
  span
    color: #f25664

.form_container
  margin: 0 auto
  width: 50%

@media only screen and (max-width: 800px)
  .form_container
    width: 85%

.row
  align-items: center
  display: flex
  justify-content: space-between
  flex-direction: column

.save_button
  background: #143169
  border-radius: 6px
  color: #fff
  cursor: pointer
  line-height: 40px
  font-size: 20px
  width: 50%
  margin: 20px 0

.fiels_simbol_required
  color: #f25664
  margin-left: 7px

.title_edit
  font-size: 26px
  font-weight: normal
  font-stretch: normal
  font-style: normal
  letter-spacing: normal
  color: #143169

.div_edit_title
  margin-left: 50px
  margin-top: 10px

.label_birthDate
  margin-top: 10px
  color: #7283a5
  font-size: 16px
  width: 100%
  line-height: 20px
  display: block
  margin-bottom: 5px
