.login
  align-items: center
  background: #0e2c67
  background: linear-gradient(45deg, #0e2c67 0%,#345aa3 100%)
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0e2c67', endColorstr='#345aa3',GradientType=1 )
  display: flex
  flex-wrap: wrap
  justify-content: center
  height: 100vh
  width: 100%

.container
  align-content: center
  background: #ffffff
  border-radius: 12px
  display: flex
  flex-wrap: wrap
  justify-content: center
  min-height: 50%
  max-width: 440px
  max-height: 680px
  padding: 44px 0
  transition: all 1s ease
  min-width: 35%

.logo,
.facebook
  display: flex
  align-items: center
  font-size: 33px
  font-weight: 300
  justify-content: center
  margin-bottom: 10px
  width: 100%
  img
    height: 50px
    margin-right: 12px

.facebook_icon
  margin-right: 10px

.form
  border-top: 1px solid #cbd0d1
  padding-top: 30px
  width: 80%

.keep_session
  align-items: center
  display: flex
  justify-content: flex-start
  margin: 16px 0
  user-select: none
  width: 100%
  span
    color: #cbd0d1
    margin-right: 8px
  label
    color: #424446
    font-weight: 300

.error_form
  color: #fb4053
  margin: 16px 0
  text-align: center
  font-weight: bold

.login_type
  display: flex
  justify-content: space-evenly
  line-height: 30px
  font-size: 20px
  width: 100%
  span
    color: #424446
    cursor: pointer
    border-bottom: 1px solid #ffffff
    font-weight: 300
    &.active
      border-bottom: 1px solid #1283a5
      font-weight: 500

.login_button
  background: #143169
  border-radius: 6px
  color: #fff
  cursor: pointer
  line-height: 40px
  font-size: 20px
  width: 100%
  margin: 20px 0 0

.forgot_password
  color: #424446
  cursor: pointer
  margin: 20px auto
  text-decoration: underline
  text-align: center
  width: 100%

.login.phone
  align-items: flex-start
  .container
    border-radius: 0 0 12px 12px
    width: 100%
    height: 90%
  .form
    width: 85%
