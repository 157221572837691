@import '_variables';

$size_footer: 55px;

.container_form {
  height: calc(100vh - 125px);
  .row_form {
    position: relative;
    width: 100%;
    height: calc(100vh - 125px);
  }
}

.col_container_progresbar {
  position: absolute;
  width: inherit;
  margin-top: 10px;
  background-color: #ebedef;
  padding: 8px;
  z-index: 1;
  .col_progresbar {
    .div_progresbar {
      width: 80%;
      display: inline-block;
    }
    .number_question {
      display: inline-block;
      margin-right: 6px;
      color: #aeb6bf;
    }
  }
}

.col_answer_question {
  padding-top: 30px;
  padding-bottom: 60px;
}

.row_quiz_mobile_first_time {
  height: 83vh;
  align-items: center;
}

.number_questions_text {
  margin-top: 5px;
  font-size: 25px;
}

.tooltip_instructions {
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 28px;
  color: $tooltip-instructions-color;
}

//list attempts
.title_attempts {
  color: #143169;
  font-size: 25px;
  padding-left: 15px;
  padding-top: 10px;
  margin-bottom: 5px;
}

.div_attempts {
  padding: 10px;
  margin: auto;
  width: 100%;
  height: 80px;
  border: 1px solid #e5e8e8;
  margin-bottom: 20px;
  &:hover {
    margin-bottom: 21px;
    -webkit-box-shadow: 0px 0px 5px 2px rgba(229, 232, 232, 1);
    -moz-box-shadow: 0px 0px 5px 2px rgba(229, 232, 232, 1);
    box-shadow: 0px 0px 5px 2px rgba(229, 232, 232, 1);
  }
}
.attempts_info {
  display: inline;
  font-size: 17px;
}

.image_quiz {
  width: 100%;
  height: 100%;
}

.percentage_info {
  font-size: 28px;
  margin-left: -10px;
  margin-bottom: 0px;
}

@media screen and (max-width: 345px) {
  .percentage_info {
    font-size: 20px;
  }
}

.show_button {
  display: none;
}

.score_attempt_rigth {
  color: $right-answer-color;
}

.score_attempt_wrong {
  color: $wrong-answer-color;
}

.div_attempts_new {
  padding: 0px;
  margin: auto;
  width: 100%;
  height: 100%;
}

.icons_size {
  font-size: 25px;
}

//question styles
.img_question_box {
  margin-top: 10px;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  .img_quiz_questions {
    width: 100%;
    height: auto;
    border-radius: 10px 10px 10px 10px;
    object-fit: contain;
  }
}

.simple_text_answer {
  padding-left: 5%;
  text-align: left;
  vertical-align: middle;
  font-size: 23px;
}

.question_text {
  text-align: center;
  margin-top: 20px;
  font-size: 23px;
}

.question_text_formula {
  text-align: center;
  margin-top: 10px;
  font-size: 23px;
}

.question_formula_scroll {
  vertical-align: middle;
  //background-color: red
  text-align: center;
  font-size: 26px;
  width: 100%;
  user-select: none;
  overflow-x: scroll;
  overflow-y: hidden;
  cursor: pointer;
}

.question_formula_scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.question_formula_scroll::-webkit-scrollbar:horizontal {
  height: 3px;
}

.question_formula_scroll::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background-color: #b2babb;
}

.wrapper_question_formula {
  vertical-align: middle;
  width: max-content;
  display: inline-block;
}

//burbuja mas de una respuesta
.tooltip_multiple_answers {
  cursor: help;
  font-size: 40px;
  color: $tooltip-multiple-answers-color;
  margin-left: 15px;
}

.blink {
  animation: blinker 1s step-start infinite;
  display: inline;
}

//answers styles

.answer_cont {
  text-align: center;
  border-radius: 6px 6px 6px 6px;
  border: 2px solid #5c84cf;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  background: linear-gradient(to right, rgba(92, 132, 207, 0.1) 11%, #fdfefe 10%, #fdfefe 15%, #fdfefe 100%);
}

.answer_cont_selected_one_answer {
  text-align: center;
  //color: white;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 6px 6px 6px 6px;
  border: 2px solid #5c84cf;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 10px;
  font-size: 18px;
  background: linear-gradient(to right, rgba(92, 132, 207, 0.1) 11%, #d5d8dc 10%, #d5d8dc 15%, #d5d8dc 100%);
  cursor: pointer;
}

.answer_cont_selected {
  text-align: center;
  //color: white;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 6px 6px 6px 6px;
  border: 2px solid #5c84cf;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 10px;
  font-size: 18px;
  background-color: $selected-answer-color;
  cursor: alias;
  background: linear-gradient(to right, rgba(92, 132, 207, 0.1) 11%, #d5d8dc 10%, #d5d8dc 15%, #d5d8dc 100%);
}

.letter_col {
  margin-left: -10px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  //background-color: red
  p {
    font-weight: 500;
    font-size: 22px;
    color: black;
    //font-weight: normal
  }
}

.img_answer_box {
  width: auto;
  height: 180px;
  justify-content: center;
  overflow: hidden;
}

.img_quiz_answers {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.letter_col_text {
  margin-left: -10px;
  margin-right: 15px;
  align-items: center;
  vertical-align: middle;
  p {
    font-weight: 500;
    font-size: 22px;
    color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

//end quiz styles
.con_end_quiz {
  height: 100vh;
  vertical-align: middle;
}

.title_end_quiz {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.style_text_result {
  color: #5c84cf;
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.div_confeti {
  margin: auto;
  width: 1%;
}

//result styles
.text_question_result {
  color: #5c84cf;
  font-size: 24px;
}

.ansers_result {
  font-size: 20px;
}

.container_question_answer {
  margin-bottom: 40px;
}

.color_score_rigth {
  color: $right-answer-color;
}

.color_score_wrong {
  color: $wrong-answer-color;
}

.answer_cont_result_rigth {
  text-align: center;
  border-radius: 6px 6px 6px 6px;
  border: 2px solid #5c84cf;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  background: linear-gradient(to right, rgba(92, 132, 207, 0.1) 11%, #d5f5e3 10%, #d5f5e3 15%, #d5f5e3 100%);
}

.answer_cont_result_wrong {
  text-align: center;
  border-radius: 6px 6px 6px 6px;
  border: 2px solid #5c84cf;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  background: linear-gradient(to right, rgba(92, 132, 207, 0.1) 11%, #f1948a 10%, #f1948a 15%, #f1948a 100%);
}

.footer_quiz {
  height: $size_footer;
  width: calc(100% - 300px);
  position: fixed;
  right: 0;
  bottom: 0;
  background-color: #ebedef;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  .col_arrows {
    display: flex;
    justify-content: space-between;

    .icon{
      margin-top: 1px;
    }

    .back_page {
      display: flex;
      justify-content: center;
      background-color: #143169;
      border-radius: 0.3rem;
      margin: 10px 12px;
      color: #ffffff;
      min-width: 90px;
      i {
        font-size: 30px;
      }
      .text_button {
        font-size: 18px; 
        margin-top: -2px;       
      }
      &.button_disabled {
        background-color: #d5d8dc;
        cursor: not-allowed;
        &:hover,
        :active {
          background-color: #d5d8dc;
        }
      }
      &:hover,
      :active {
        background-color: #265ecb;
      }
    }
  }
}

.foother_quiz_free {
  height: $size_footer;
  width: 100%;
  position: fixed;
  right: 0;
  bottom: 0;
  background-color: #ebedef;
  .col_arrows {
    display: flex;
    justify-content: space-between;

    .icon{
      margin-top: 1px;
    }

    .back_page {
      display: flex;
      justify-content: center;
      background-color: #143169;
      border-radius: 0.3rem;
      margin: 10px 12px;
      color: #ffffff;
      min-width: 90px;
      i {
        font-size: 30px;
      }
      .text_button {
        font-size: 18px; 
        margin-top: -2px;       
      }
      &.button_disabled {
        background-color: #d5d8dc;
        cursor: not-allowed;
        &:hover,
        :active {
          background-color: #d5d8dc;
        }
      }
      &:hover,
      :active {
        background-color: #265ecb;
      }
    }
  }
}
