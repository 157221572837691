.container {
	background-color: rgb(61, 105, 183);
	border-radius: 10px;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
	padding: 30px 30px;
	max-width: 100%;
	width: 260px;
	margin: 10px;
	.header {
		padding-top: 10px;
	}
	.timeline{
		li{
			padding: 0 0 10px;
			height: 25px;
		}
	}
	p {
		opacity: 0.7;
		padding-bottom: 2px;
		color: #f2f4ff;
	}

	p.students {
		color: rgb(61, 105, 183);
		margin-left: 10px;
		margin-top: -15px;
		padding-bottom: 15px;
	}

	h1 {
		color: #f2f4ff;
		margin-top: -10px;
		font-size: 22px;
	}

	.card {
		background-color: #f2f4ff;
		border-radius: 10px;
	}

	.icon {
		padding-top: 15px;
		padding-bottom: 5px;
	}

	h2 {
		color: rgb(61, 105, 183);
		margin: 10px;
		margin-top: -10px;
	}

	ul {
		color: #f2f4ff;
		list-style-type: none;
		padding: 0;
	}
	.title_li{
		font-size: 22px;
		padding-bottom: 5px;
		padding-top: 10px;
		font-weight: 600;
	}

	li:nth-child(5) {
		opacity: 70%;
	}
	.button{
		&:hover{
			background-color:#d7d7d7
		}
	}
}
