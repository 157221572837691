.booking_schedule
    .schedule_widget
        overflow-x: auto
    .day_slot
        ul
            display: -webkit-box
            display: -ms-flexbox
            display: flex
            div
                padding-left: 0
                padding-right: 0
        li
            -ms-flex: 0 0 100px
            flex: 0 0 100px
            width: 100%

        .right_arrow
            right: -20px

    .time_slot
        ul
            display: -webkit-box
            display: -ms-flexbox
            display: flex

        li
            -ms-flex: 0 0 100px
            flex: 0 0 100px
            .timing
                span
                    display: inline-block

.booking_doc_info
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    .booking_doc_img
        width: 80px
        margin-right: 15px
        img
            border-radius: 4px
            height: 80px
            width: 80px
            object-fit: cover

.schedule_widget
    border-radius: 4px
    min-height: 100px

.schedule_header
    border-bottom: 1px solid #f0f0f0
    border-radius: 4px 4px 0 0
    padding: 10px 20px
    h4
        font-size: 18px
        font-weight: 600
        margin: 3px 0 0

.day_slot
    ul
        float: left
        list-style: none
        margin-bottom: 0
        margin-left: -5px
        margin-right: -5px
        padding: 0
        position: relative
        width: 100%
    li
        float: left
        padding-left: 5px
        padding-right: 5px
        text-align: center

        span
            display: block
            font-size: 18px
            text-transform: uppercase
            .slot_date
                display: block
                color: #757575
                font-size: 14px
        small
            .slot_year
                color: #757575
                font-size: 14px
        .left_arrow
            left: 0
            padding: 0
            position: absolute
            text-align: center
            top: 50%
            width: 20px !important
            transform: translateY(-50%)
        .right_arrow
            right: -11px
            padding: 0
            position: absolute
            text-align: center
            top: 50%
            width: 20px !important
            transform: translateY(-50%)




.schedule_cont
    padding: 20px

.time_slot
    ul
        list-style: none
        margin-right: -5px
        margin-left: -5px
        margin-bottom: 0
        padding: 0
    div
            padding-left: 0
            padding-right: 0
    li
        padding-left: 5px
        padding-right: 5px
        .timing
            background-color: #e9e9e9
            border: 1px solid #e9e9e9
            border-radius: 3px
            color: #757575
            display: block
            font-size: 14px
            margin-bottom: 10px
            padding: 5px 5px
            text-align: center
            position: relative
        .timing:hover
            background-color: #fff
        .timing:last-child
            margin-bottom: 0

        .timing.selected
            background-color: #42c0fb
            border: 1px solid #42c0fb
            color: #fff

        .timing.selected::before
            color: #fff
            content: "\f00c"
            font-family: "Font Awesome 5 Free"
            font-size: 12px
            font-weight: 900
            position: absolute
            right: 6px
            top: 6px

.schedule_list
    border-bottom: 1px solid #cfcfcf
    margin-bottom: 50px
    padding-bottom: 50px

.schedule_list:last-child
    border-bottom: 0
    margin-bottom: 0
    padding-bottom: 0

.pricing_card
  text-align: center
  &.pricing_card_success
    border-top: 5px solid rgb(46, 204, 113)

.circle
  fill-opacity: 0
  stroke: #219a00
  stroke-width: 16px
  transform-origin: center
  transform: scale(0)
  animation: grow 1s cubic-bezier(.25, .25, .25, 1.25) forwards

@keyframes grow
  60%
    transform: scale(.8)
    stroke-width: 4px
    fill-opacity: 0

  100%
    transform: scale(.9)
    stroke-width: 8px
    fill-opacity: 1
    fill: #219a00

@keyframes draw
  100%  
    stroke-dashoffset: 0

.svg_container
  height: 70px
  margin-bottom: 30px
  

.container_button
  display: flex
  align-items: center
  text-align: center
  align-content: center
  justify-content: center
  .save_button
    background: #143169
    border-radius: 6px
    color: #fff
    cursor: pointer
    line-height: 40px
    font-size: 20px
    width: 30%
    margin: 20px 0
    right: 0
    left: 0
    &:hover
      background: #143169
