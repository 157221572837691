@import '_variables'


.number_questions
  color: #5c84cf
  font-size: 20px

.text_answers_example
  color: #808B96

.row_top
  margin-top: -17px
  //background-color: yellow

.hide_Buttons
  display: none

.question_cont_text
  font-size: 23px
  text-align: center
   
.line_question
  width: 20%
  margin-left: auto
  margin-right: auto
  border-width: 4px

.question_text
  color: $color-text-question
  font-size: 26px

.question_text_formula
  font-size: 30px
  //color: $color-text-question

.answer_cont
  border-radius: 6px 6px 6px 6px
  border: 2px solid #5c84cf
  margin-top: 20px
  margin-left: auto
  margin-right: auto
  width: 90%
  padding: 20px
  font-size: 18px
  cursor: pointer
  background: linear-gradient(to right,  rgba(92, 132, 207, 0.1) 11%,#FDFEFE 10%,#FDFEFE 15%,#FDFEFE 100%)

.answer_cont_selected
  color: white
  font-weight: 550
  letter-spacing: 1px
  border-radius: 6px 6px 6px 6px
  border: 2px solid #5c84cf
  margin-top: 20px
  margin-left: auto
  margin-right: auto
  width: 90%
  padding: 20px
  font-size: 18px
  background-color: $selected-answer-color
  cursor: alias
  background: linear-gradient(to right,  rgba(92, 132, 207, 0.1) 11%,#58D68D 10%,#58D68D 15%,#58D68D 100%)

.answer_cont_selected_one_answer
  color: white
  font-weight: 550
  letter-spacing: 1px
  border-radius: 6px 6px 6px 6px
  border: 2px solid #5c84cf
  margin-top: 20px
  margin-left: auto
  margin-right: auto
  width: 90%
  padding: 20px
  font-size: 18px
  background: linear-gradient(to right,  rgba(92, 132, 207, 0.1) 11%,#58D68D 10%,#58D68D 15%,#58D68D 100%)
  cursor: pointer

.container_answer
  width: 100%
  height: auto
  margin: 0 auto
  padding: 10px
  position: relative

.tooltip_multiple_answers
  cursor: help
  font-size: 40px
  color: $tooltip-multiple-answers-color

.selected_Answer_color
  background-color: $selected-answer-color  

.style_text_result
  color: #5c84cf
  font-size: 28px
  margin-top: 10px
  margin-bottom: 10px

.title_end_quiz
  font-size: 28px
  margin-top: 10px
  margin-bottom: 10px

.question_text_result
  color: #808B96
  font-size: 24px

.number_text_result
  font-size: 28px
  margin-right: 15px

.answers_container
  margin-left: 50px
  margin-top: 0px
  margin-bottom: 20px

.text_true_answer
  font-size: 18px
  text-decoration: underline
  margin-right: 10px
  margin-left: 15px

.answer_div
  margin-bottom: 15px

.container_video
  margin-right: 10px
  display: inline-block
  border: 5px solid #808B96 
  border-radius: 4px 4px 4px 4px
  cursor: pointer

.videocontainer
  background: #000000
  //min-height: 700px
  height: 470px
  width: 100%
  margin: 20px auto
  display: flex
  align-content: center
  justify-content: center
  max-height: 600px
  & > video
    width: 100%

.description_video
  font-size: 17px

.color_score_rigth
  color: $right-answer-color

.color_score_wrong
  color: $wrong-answer-color

.con_formula
  margin-bottom: 15px

.con_img
 margin-bottom: 15px

.img_quiz_answers
  height: 100%
  width: auto
  border-radius: 5px 5px 5px 5px
  -moz-border-radius: 5px 5px 5px 5px
  -webkit-border-radius: 5px 5px 5px 5px

.img_quiz_questions
  height: 100%
  width: auto
  margin-bottom: 15px
  border-radius: 10px 10px 10px 10px
  -moz-border-radius: 10px 10px 10px 10px
  -webkit-border-radius: 10px 10px 10px 10px
  //border: 6px solid #CCD1D1

.img_question_box
  width: 100%
  height: 280px
  display: flex
  justify-content: center
  margin-bottom: 30px
  overflow: hidden

.img_answer_box
  width: 100%
  height: 150px
  display: flex
  justify-content: center
  overflow: hidden

.text_image_resp
  display: block

.img_quiz_answers_resp
  margin-left: 15px
  cursor: zoom-in
  height: 140px
  width: auto
  border-radius: 5px 5px 5px 5px
  -moz-border-radius: 5px 5px 5px 5px
  -webkit-border-radius: 5px 5px 5px 5px
  margin-bottom: 10px

.letter_col
  display: flex
  align-items: center
  p
    color: black
    font-weight: normal

.title_attempts
  margin-bottom: 15px
  color: #143169

.div_attempts
  padding: 15px
  margin: auto
  width: 65%
  height: 90px
  border: 1px solid #E5E8E8
  margin-bottom: 20px
  &:hover 
    margin-bottom: 21px
    -webkit-box-shadow: 0px 0px 5px 2px rgba(229,232,232,1)
    -moz-box-shadow: 0px 0px 5px 2px rgba(229,232,232,1)
    box-shadow: 0px 0px 5px 2px rgba(229,232,232,1)

.attempts_info
  display: inline
  font-size: 17px

.score_attempt_rigth
  color: $right-answer-color

.score_attempt_wrong
  color: $wrong-answer-color

.div_attempts_new
  padding: 15px
  margin: auto
  width: 65%
  height: 150px
 
.blink 
  animation: blinker 1s step-start infinite
  display: inline

@keyframes blinker
  50% 
    opacity: 0

.icons_size
  font-size: 80px
  &:hover
    color: #5c84cf

.col_answer_question
  margin-top: -5px
  //background-color: red

.col_arrows
  margin: auto
  //background-color: green

.scroll_answers
  margin-top: 0px
  float: left
  //height: calc(100% - 130px)
  height: calc(100vh - 120px)
  width: 100%
  overflow-y: scroll

.scroll_answers::-webkit-scrollbar 
  -webkit-appearance: none

.scroll_answers::-webkit-scrollbar:vertical 
  width: 4px

.scroll_answers::-webkit-scrollbar-thumb 
  border-radius: 3px
  background-color:#B2BABB

//mobile quizz styes
.row_quiz_mobile_first_time
  height: 83vh
  align-items: center
  //background-color: green

.number_questions_text
  margin-top: 5px
  font-size: 25px
  //color: red

