$animation-duration-base: 0.2s;
$animation-duration-fast: 0.1s;
$ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-out-circ: cubic-bezier(0.08, 0.82, 0.17, 1);
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.34);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);
$primary-color: blue;
$wave-animation-width: 6px;



@mixin motion-common($duration: $animation-duration-base){
    animation-duration: $duration;
    animation-fill-mode: both;
  }
  
  @mixin motion-common-leave($duration: $animation-duration-base){
    animation-duration: $duration;
    animation-fill-mode: both;
  }
  
  @mixin make-motion($className, $keyframeName, $duration: $animation-duration-base){
    .#{$className}-enter,
    .#{$className}-appear {
      @include motion-common($duration);
  
      animation-play-state: paused;
    }
    .#{$className}-leave {
      @include motion-common-leave($duration);
  
      animation-play-state: paused;
    }
    .#{$className}-enter.#{$className}-enter-active,
    .#{$className}-appear.#{$className}-appear-active {
      animation-name: #{$keyframeName}In;
      animation-play-state: running;
    }
    .#{$className}-leave.#{$className}-leave-active {
      animation-name: #{$keyframeName}Out;
      animation-play-state: running;
      pointer-events: none;
    }
  }