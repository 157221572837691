@mixin slide-motion($className, $keyframeName){
    @include make-motion($className, $keyframeName);
    .#{$className}-enter,
    .#{$className}-appear {
      opacity: 0;
      animation-timing-function: $ease-out-quint;
    }
    .#{$className}-leave {
      animation-timing-function: $ease-in-quint;
    }
  }
  
  @include slide-motion(slide-up, antSlideUp);
  @include slide-motion(slide-down, antSlideDown);
  @include slide-motion(slide-left, antSlideLeft);
  @include slide-motion(slide-right, antSlideRight);
  
  @keyframes antSlideUpIn {
    0% {
      transform: scaleY(0.8);
      transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      transform: scaleY(1);
      transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  
  @keyframes antSlideUpOut {
    0% {
      transform: scaleY(1);
      transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      transform: scaleY(0.8);
      transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  
  @keyframes antSlideDownIn {
    0% {
      transform: scaleY(0.8);
      transform-origin: 100% 100%;
      opacity: 0;
    }
    100% {
      transform: scaleY(1);
      transform-origin: 100% 100%;
      opacity: 1;
    }
  }
  
  @keyframes antSlideDownOut {
    0% {
      transform: scaleY(1);
      transform-origin: 100% 100%;
      opacity: 1;
    }
    100% {
      transform: scaleY(0.8);
      transform-origin: 100% 100%;
      opacity: 0;
    }
  }
  
  @keyframes antSlideLeftIn {
    0% {
      transform: scaleX(0.8);
      transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      transform: scaleX(1);
      transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  
  @keyframes antSlideLeftOut {
    0% {
      transform: scaleX(1);
      transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      transform: scaleX(0.8);
      transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  
  @keyframes antSlideRightIn {
    0% {
      transform: scaleX(0.8);
      transform-origin: 100% 0%;
      opacity: 0;
    }
    100% {
      transform: scaleX(1);
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }
  
  @keyframes antSlideRightOut {
    0% {
      transform: scaleX(1);
      transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      transform: scaleX(0.8);
      transform-origin: 100% 0%;
      opacity: 0;
    }
  }
  