.profile {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    background-image:url("../../../../../assets/pattern.png"),linear-gradient(45deg, rgb(96, 15, 57) 0%, rgb(112, 0, 71) 100%);
    background-repeat: repeat;
    
    padding: 0;
    margin: 0;
    border-radius: 0 0px 15px 15px;
    transition: all 0.3s;
    .card {
        width: 100%;
        height: 100%;
        .card_bio {
            height: 100%;
            margin: 0 15px;
            display: flex;
            flex-direction: column;
            position: relative;
            .img_wrapper {
                width: 90px;
                height: 90px;
                align-self: center;
                margin: 10px 0;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                    background-color: white;
                }
            }
            .person_info {
                display: flex;
                position: relative;
                align-self: center;
                h3 {
                    font-size: 22px;
                    color: #fff;
                }
            }
        }
        .card_btn {
            width: 150px;
            height: 40px;
            position: absolute;
            right: 25%;
            top: 180px;
            border-radius: 40px;
            border: none;
            background-color:rgb(91,173,69);
            
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 1px;
            color: #fff;
            box-shadow: 0 0 2px 4px rgba(0, 0, 0, 0.3);
            outline: none;
            cursor: pointer;
            z-index: 2;
            transition: all 0.5s;
            &:hover {
                box-shadow: 0 0 60px 2px rgb(91,173,69), 0.5rem 0.5rem 30px mix(black, #26115a, 50%);
            }
            .container_content_button {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin-top: 11px;
            }
            .card_btn_more_info {
                opacity: 1;
                transition: all 0.8s;
            }
            .icon_button {
                opacity: 0;
                visibility: hidden;
                font-size: 28px;
                color: #fff;
                transition: all 0.3s 0.3;
            }
            .qr {
                align-self: center;
            }
        }
    }
    .contentMoreInfo {
        animation: fadeOut 0.3s;
        animation-fill-mode: forwards;
        .label {
            height: 40px;
            background: rgb(237, 205, 55);
            box-shadow: 8px 5px 5px;

            padding: 8px 10px;
            // border-radius: 20px 2px;
            transform: scale(0);
            transform-origin: left;
            h4 {
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 1px;
                color: #fff;
                text-shadow: 0 0 10px 15px rgba(0, 0, 0, 0.3);
            }
        }
        .container_box_info {
            margin: 0 10px;
            padding: 0;
        }
        .contentMoreInfo_info {
            margin-top: 20px;
            margin-right: -10px;
            padding: 10px;
            background-color: #fff;
            border-radius: 5px;
            opacity: 0;
            transform: translateY(-50%);
            position: absolute;
            box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
            .profile_row {
                display: flex;
                label {
                    margin-left: 10px;
                    font-size: 15px;
                }
            }
        }
        .container_qr {
            display: flex;
            flex-direction: column;
            position: relative;
            .qr {
                height: 150px;
                width: 150px;
                align-self: center;
            }
        }
    }
    &.open {
        height: 100%;
        border-radius: 0;
        transition: all 0.3s;
        .card {
            height: 200px;
        }
        .card_btn {
            width: 40px;
            height: 40px;
            top: 180px;
            right: 20px;
            transition: all 0.5s;
            .container_content_button {
                margin-top: 5px;
            }
            .card_btn_more_info {
                opacity: 0;
                visibility: hidden;
                transform: scaleY(0);
                transition: all 0.2s;
            }
            .icon_button {
                opacity: 1;
                visibility: visible;
                transition: all 0.3s 0.3;
            }
        }
        .contentMoreInfo {
            animation: fadeOut 0.3s;
            animation-fill-mode: backwards;
            .label {
                transform: scale(1);
                transition: all 0.5s;
            }
            .contentMoreInfo_info {
                opacity: 1;
                transform: translateY(0);
                position: relative;
                // left: 0px;
                transition: all 0.3s;
            }
        }
    }
}

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}
@include keyframes(fadeOut) {
    0% {
        opacity: 1;
        z-index: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0;
        z-index: -1;
    }
}
