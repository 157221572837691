@import "../settings/variable.scss";
.container {
    @media screen and (min-width: 768px) {
        width: 100%;
        max-width: 1630px;
    }
}
.container_wrapper {
    padding-left: 0;
    padding-top: 70px;
    min-height: 100vh;
    transition: padding-left 0.3s;
    background: $colorBackgroundBody;

    @media screen and (min-width: 768px) {
        padding-left: 300px;
    }
}

.layout {
    &.layout--collapse {
        & + .container_wrap {
            padding-left: 0;

            @media screen and (min-width: 768px) {
                padding-left: 60px;
            }
        }
    }
    
    &.layout--top-navigation {
        & + .container_wrap {
            @media screen and (min-width: 768px) {
                padding-left: 0;
            }
        }
    }
}
