.section
  width: 100%
  min-height: calc(100vh - 70px)
  
.wrapper_main
   transition: all 0.3s
.save_btn
  background: linear-gradient(to bottom, #608ce4 20%, #265ecb 100%)
  border-radius: 6px
  border: 1px solid #84bbf3
  display: inline-block
  cursor: pointer
  color:#ffffff
  font-size: 18px
  font-weight: bold
  padding: 15px 41px
  text-decoration: none

.save_btn:hover
  color: #d7d7d7

@media only screen and (max-width: 1200px)
  .save_btn
    padding: 5px 15px
    font-size: 15px
    font-weight: normal
  
  
.account
  position: absolute
  right: 25px
  top: 8px
  span
    font-size: 40px
    
.chat_container
  display: flex
  width: 100%
  flex-direction: column
  align-items: center
  justify-items: center


.home_header
  display: flex
  padding: 20px
  justify-content: space-between
  align-items: center
  .button_container
    margin-left: auto

.title
  color: #143169
  font-size: 24px

.courses
  margin-bottom: 15px
  overflow-x: auto
  overflow-y: hidden
  -webkit-overflow-scrolling: touch
  width: 100%

.wrapper_course
  height: 160px
  width: max-content

.course
  display: inline-block
  vertical-align: top
  background: #d7d7d7
  border-radius: 10px
  width: 220px
  height: 140px
  margin: 0 12px
  position: relative
  cursor: pointer

.couse_image
  border-radius: 10px
  height: 100%
  width: 100%

.online_couse_image
  border: 2px solid #d7d7d7
  background: #ffffff
  border-radius: 10px
  padding: 20px
  height: 100%
  width: 100%
  cursor: pointer
  transition: all 0.3s
  &:hover
    transition: all 0.3s 
    transform: scale(1.1)
  img
    height: 100%
    width: 100%
  h3
    text-align: center

.course_arrow,
.course_arrow_active
  bottom: -47px
  color: #fff 
  font-size: 80px
  left: calc(50% - 40px)
  position: absolute

.hide_arrow
  display: none

.course_arrow_active
  bottom: -46px
  color: rgb(251,65 ,86 )

.wrapper_title
  position: relative
  top: -50px
  display: block
  color: #fff
  font-size: 20px
  padding: 0 16px
  height: auto

.course_detail
  padding: 0 20px
  width: 100%
  @media screen and (max-width: 400px)
    padding: 0 10px

.course_type
  color: #265ecb
  font-size: 20px

.course_title
  color: #143169
  font-size: 24px
  &:hover
    text-decoration: underline

.details
  display: flex
  align-items: baseline
  justify-content: center
  margin-top: 20px
  width: 100%
  flex-wrap: wrap

.playlist_container_desktop,
.detail_container_desktop
  min-height: 300px
  width: 50%
  padding: 20px

.playlist_container_tablet,
.detail_container_tablet,
.playlist_container_phone,
.detail_container_phone
  width: 100%


.group
  align-content: center
  border-top: 1px solid #b8b8b8
  border-bottom: 1px solid #b8b8b8
  display: flex
  flex-wrap: wrap
  margin: 8px auto
  padding: 16px 0
  width: 90%
  & > p
    width: 80%


.attendance
  width: 80%
  margin: 20px auto 0
  & > span
    color: #265ecb

.bar
  border: 1px solid #b8b8b8
  border-radius: 10px
  margin-top: 8px
  height: 10px
  width: 100%

.progress
  background: #2ecc71
  border-radius: 10px
  height: 100%

.attendance_details
  margin: 16px 0
  text-align: center
  width: 100%
  & > p
    align-items: center
    display: flex
    height: 20px
    justify-content: space-around
    & > span
      color: #000000


.playlist_desktop
  padding-top: 72px
  width: 40%

.playlist_mobile
  margin: 0 auto
  width: 90%

.playlist
  color: #666666
  display: inline-block 
  align-items: center
  margin-bottom: 16px
  font-size: 20px

.folder_icon,
.folder_icon_child
  font-size: 26px
  color: #255ecb
  margin-right: 12px

.folder_icon_child
  color: #fb9000

.playlist_child
  padding-left: 16px

.poster
  margin-right: 20px
  height: 70px
  width: 100px

.empty
  font-size: 24px
  color: #828282
  display: flex
  align-items: center
  justify-content: center
  flex-wrap: wrap
  margin-top: 100px
  & > p
    width: 100%
    text-align: center

.cont_name_parent_file
  display: inline-block 
  font-size: 26px
  color: black
  margin-left: 15px
  cursor: pointer

.cont_name_parent_file:hover
  color: #255ecb

.empty_component
  width: 50%
  text-align: center

.disable_cont_courses
  pointer-events: none
  