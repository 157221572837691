.main_conatiner_free_exams {
  height: 100vh;
//   overflow: hidden;
  //   background-color: red;

  @media screen and (max-width: 900px) {
    height: 100%;
    // overflow: auto;
  }
}
