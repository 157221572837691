.container {
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
}

.title {
  color: #143169;
  font-size: 24px;
  text-align: left;
  margin: 0 auto 12px;
}

.metrics {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #000000;

  &>span {
    margin: 0 4px;
  }
}

.iconplay {
  color: #7fabff;
}

.iconstar {
  color: #fcb400;
}

.videoWrap {
  display: flex;
  align-content: center;
  justify-content: center;

  .videocontainer {
    min-width: 100%;
    background: #000000;

    @media screen and (min-width: 1500px) {
      min-width: 1200px;
      max-width: 1200px;
    }
  }
}

.description {
  width: 100%;
  text-align: justify;
}

.score {
  text-align: center;

  &>p {
    margin: 12px auto;
  }
}

.text_area_styles {
  width: 95%;
  resize: vertical;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 10px;
  min-height: 40px;
  max-height: 300px;
  line-height: 1.5;
}

.container_comments {
  padding: 60px;
}

.ver_respuestas {
  text-decoration: none;
  margin-left: 15px;
  margin-right: 5px;

  &:hover {
    text-decoration: underline;
    color: #143169;
  }
}

.profile_icon {
  font-size: 40px;
}

.delete_and_edit {
  font-size: 13px;
  margin: 10px;
  padding: 0;
  cursor: pointer;

}

.formButtons{
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}