.save_btn
  background: #265ecb
  border: 1px solid #265ecb
  border-radius: 20px
  font-size: 16px
  justify-content: center
  min-width: 90px
  padding: 6px

.title
  color: #143169
  font-size: 20px

.container
  width: 90%
  margin: 20px auto

.container_button
  display: flex
  align-items: center
  text-align: center
  align-content: center
  justify-content: center
  .save_button
    background: #143169
    border-radius: 6px
    color: #fff
    cursor: pointer
    line-height: 40px
    font-size: 20px
    width: 30%
    margin: 20px 0
    right: 0
    left: 0
    &:hover
      background: #143169

.course_selector
  width: 100%
  display: flex
  align-content: center
  justify-content: flex-start
  color: #265ecb
  font-size: 16px
  margin-top: 20px

.courses
  color: #143169
  margin-right: 12px

.course_list
  align-items: center
  display: flex
  justify-content: space-between
  margin: 4px auto
  color: #4a4a4a
  padding: 12px
  width: 95%
  &.active
    background: #dee7f1
    border: 1px solid #ecf0f1

.list_element_left, .list_element_center
  width: 50%
  text-align: left
  font-size: 14px
  p
    color: #000000
    font-size: 16px
    margin: 0

.list_element_center
  text-align: center
.pricing_card
  text-align: center
  &.pricing_card_success
    border-top: 5px solid rgb(46, 204, 113)

.circle
  fill-opacity: 0
  stroke: #219a00
  stroke-width: 16px
  transform-origin: center
  transform: scale(0)
  animation: grow 1s cubic-bezier(.25, .25, .25, 1.25) forwards

@keyframes grow
  60%
    transform: scale(.8)
    stroke-width: 4px
    fill-opacity: 0

  100%
    transform: scale(.9)
    stroke-width: 8px
    fill-opacity: 1
    fill: #219a00

@keyframes draw
  100%  
    stroke-dashoffset: 0

.svg_container
  height: 70px
  margin-bottom: 30px
