.calendar_container
  position: relative
  display: flex
  flex-direction: column

.calendar
  align-self: center
  
.arrow
  color: #265ecb
  font-size: 40px

.day, .absence_day
  padding: 1.5rem 0.5rem
  font-size: 16px
  font-weight: lighter
  position: relative
  overflow: unset !important

.absence_day
  color: #f25664
  font-weight: bold
  text-decoration: underline
  &:focus
    color: white
  &:active
    color: white

.absence_tooltip,
.absence_tooltip_left
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.18)
  background: #5c84cf
  border-radius: 4px
  color: #ffffff
  position: absolute
  top: calc(50% - 30px)
  width: 140px
  height: 60px
  z-index: 1
  font-size: 12px
  display: flex
  align-items: center
  padding: 8px
  flex-wrap: wrap
  flex: 1 1
  font-weight: normal
  justify-content: center

.absence_tooltip
  left: 90%

.absence_tooltip_left
  right: 95%

.tooltip_arrow_left
  color: #5c84cf
  font-size: 40px
  position: absolute
  left: -23px

.tooltip_arrow_right
  color: #5c84cf
  font-size: 40px
  position: absolute
  right: -22px

.comments
  width: 90%
  margin: 0 auto

.actived_courses
  color: #265ecb
  font-size: 16px
  margin: 12px auto 20px
  display: flex
  align-items: center

.comment_container
  border-bottom: 1px solid #979797
  margin-bottom: 20px
  width: 100%

.comment_container:last-child
  border-bottom: 1px solid #ffffff

.comment_title
  align-items: center
  display: flex

.comment_img
  border-radius: 30px
  border: 1px solid #4a4a4a
  color: #4a4a4a
  font-size: 26px
  height: 30px
  overflow: hidden
  text-align: center
  width: 30px
  & > img
    height: 100%

.comment_name
  font-size: 16px
  margin: 0 20px 0 12px

.comment_date
  font-size: 12px
  color: #4a4a4a

.comment
  padding-left: 44px
  text-align: justify
  padding-right: 12px
  font-size: 13px
  margin: 10px auto 20px
