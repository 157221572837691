.container {
  //   background-color: aqua;
  padding: 10px 20px;

  h5 {
    padding-left: 25px;
  }

  .group_list {
    padding: 0px 35px;
    cursor: pointer;
    margin-bottom: 20px;
    // background-color: pink;
    p {
      margin-bottom: 0px;
      font-weight: 800;
      font-size: 19px;
    }

    :hover {
      background-color: #f5f5f5;
      border-radius: 4px;
    }
  }

  .selected {
    color: #143169;
    span {
      font-weight: 600;
    }
  }

  .button_container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    .register {
      background: #143169;
      border: 1px solid #143169;
      font-size: 20px;
      justify-content: center;
      min-width: 90px;
      padding: 6px 60px;
    }
  }
}

.container_card {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.nogroups {
  height: 150px;
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}
