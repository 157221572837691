.button{
    width: 35px;
    height: 30px;
    align-self: center;
    cursor: pointer;
    margin-left:15px;
    > span {
      width: 100%;
      height: 3px;
      margin: 3px 0;
      float: right;
      background: #ff4361;
      transition: all 0.3s ease;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        width: calc(100% - 10px);
        margin-bottom: 0;
      }
    }
    &:focus,
    &:hover {
      outline: none;
      > span {
        &:last-child {
          width: 100%;
        }
      }
    }
    &:focus,
    &.active {
      > span {
        &:first-child {
          transform: rotate(45deg);
          transform-origin: 8px 50%;
        }
        &:nth-child(2) {
          display: none;
        }
        &:last-child {
          width: 100%;
          transform: rotate(-45deg);
          transform-origin: 9px 50%;
        }
      }
    }
}