.steps_content {
  margin-top: 20px;
  width: 100%;
}
.steps_action {
  background-color: red;
}

.step_form_main {
  padding: 20px 20px;
  // background-color: gainsboro;
  min-width: 50%;
}


