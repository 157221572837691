$redColor: rgb(250, 83, 108);
$greenColor: rgb(46, 204, 113);
$yellowColor: rgb(255, 203, 0);
$grayColor: #787985;

$colorBackgroundBody: #fff;
$colorBackground: #ffff;
$colorHover: #ffff;
$colorText: #000000;
$colorIcon: #f0f0f0;
$colorBorder: #f0f0f0;

$color-accent: #3057af;
$color-accent-hover: darken($color-accent, 10%);
$colorText: #646777;
$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);

//SIDEBAR
$colorBackgroundSidebarMenu: #11306c;
$colorBackgroundSidebar: #ecf0f1;
$colorHoverSidebar: #30559d;
$colorWhiteText: #ffffff;
$colorSidebarActive: #6fa0ff;
$colorHoverSidebarSub: #30559d;

$color-blue: #70bbfd;
$color-blue-hover: darken($color-blue, 10%);

$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);
$color-gray: gray;

$logoImg: url("/assets/logo.png");

$color-blue-dash: #3057af;
$color-blue-dash-hover: darken($color-blue-dash, 10%);
