.table_margin
  width: 100%
  padding: 0px 25px 0px 25px
  //background-color:  #fca400
.title
  font-size: 30px
  color: pink

.accountselector
  align-content: center
  border: 1px solid #cbd0d1
  border-radius: 6px
  display: flex
  color: #265ecb
  font-size: 16px
  margin: 20px auto
  justify-content: center
  line-height: 40px
  padding: 0 12px
  width: 90%

.header_selector
  display: block
  align-items: left
  justify-content: space-around
  margin: 20px auto 20px
  width: 90%

.tab
  color: #4a4a4a
  border-bottom: 1px solid #ffffff
  padding-bottom: 8px

.active_tab
  color: #265ecb
  border-bottom: 1px solid #265ecb
  padding-bottom: 8px

.addaccount
  background: #143169
  height: 40px
  width: 40px
  border-radius: 40px
  color: #ffffff
  text-align: center
  font-size: 30px
  display: flex
  align-items: center
  justify-content: center

.row
  width: 90%
  margin: 20px auto
  padding: 0 12px

.course, .course_payment
  height: 24px
  display: flex
  align-items: center

.course_payment
  color: #424446
  justify-content: space-between

.payment_icon
  width: 30px

.balances
  background: #ecf0f1
  border-bottom: 1px solid #ffffff
  font-size: 20px
  height: 50px
  display: flex
  align-items: center
  justify-content: space-between
  margin: 0 auto 1px
  padding: 0 12px
  width: 90%

.arrow_left
  color: #A4BADF
  font-size: 40px

.arrow_down
  color: #fca400
  font-size: 40px

.text_table
  text-align: center
  //background-color:  #fca400

.concept_cont
  width: 35%

@media only screen and (max-width: 800px)
  .table_margin
    margin: 0px 0px 0px 0px

.collapse_div
  margin-top: 55px
  padding: 10px 30px
  //background-color: red

.debt_div 
  padding: 8px
  background-color: #ecf0f1
  font-size: 16px
  margin-right: 20px


@media only screen and (max-width: 900px) 
  .collapse_div
    padding: 0px 0px
  .debt_div
    font-size: 14px
    margin-right: 2px



.boxDataPayments 
  //height: 40px;
  background-color: #ecf0f1
  //text-align:left;
  text-align: center
  vertical-align: middle
  line-height: 40px
  font-size: 14px


