.header
  height: 50px
  display: flex
  align-items: center
  justify-content: space-between
  color: white
  background: #143169
  font-size: 20px
  padding: 0 20px
  font-weight: 300
  border-bottom: 1px solid #656565
  position: sticky
  top: 0
  left: 0
  p
    display: flex
    align-items: center
    a
      display: inherit

.arrow_left
  color: #265ecb
  font-size: 36px

.home_header_mobile
  font-weight: 300
  color: #ffffff
  background: #143169
  align-items: center
  display: flex
  justify-content: center
  height: 56px
  font-size: 24px
  position: relative
  top: 0
  width: 100%

.account
  position: absolute
  right: 40px
  height: 40px
  overflow: hidden
  border-radius: 40px
  span
    font-size: 36px
  img
    height: 100%

.header_desktop
  height: 70px
  border-bottom: 1px solid #ecf0f1
  background: #ffffff
  display: flex
  justify-content: center
  align-items: center
  font-size: 20px
  font-weight: lighter
  img
    height: 50%
    margin: 0 16px

.hide_back
  display: none
