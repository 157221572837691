@mixin zoom-motion($className, $keyframeName, $duration: $animation-duration-base){
    @include make-motion($className, $keyframeName, $duration);
    .#{$className}-enter,
    .#{$className}-appear {
      transform: scale(0); // need this by yiminghe
      opacity: 0;
      animation-timing-function: $ease-out-circ;
    }
    .#{$className}-leave {
      animation-timing-function: $ease-in-out-circ;
    }
  }
  
  // For Modal, Select choosen item
  @include zoom-motion(zoom, antZoom);
  // For Popover, Popconfirm, Dropdown
  @include zoom-motion(zoom-big, antZoomBig);
  // For Tooltip
  @include zoom-motion(zoom-big-fast, antZoomBig, $animation-duration-fast);
  
  @include zoom-motion(zoom-up, antZoomUp);
  @include zoom-motion(zoom-down, antZoomDown);
  @include zoom-motion(zoom-left, antZoomLeft);
  @include zoom-motion(zoom-right, antZoomRight);
  
  @keyframes antZoomIn {
    0% {
      transform: scale(0.2);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes antZoomOut {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.2);
      opacity: 0;
    }
  }
  
  @keyframes antZoomBigIn {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes antZoomBigOut {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
      opacity: 0;
    }
  }
  
  @keyframes antZoomUpIn {
    0% {
      transform: scale(0.8);
      transform-origin: 50% 0%;
      opacity: 0;
    }
    100% {
      transform: scale(1);
      transform-origin: 50% 0%;
    }
  }
  
  @keyframes antZoomUpOut {
    0% {
      transform: scale(1);
      transform-origin: 50% 0%;
    }
    100% {
      transform: scale(0.8);
      transform-origin: 50% 0%;
      opacity: 0;
    }
  }
  
  @keyframes antZoomLeftIn {
    0% {
      transform: scale(0.8);
      transform-origin: 0% 50%;
      opacity: 0;
    }
    100% {
      transform: scale(1);
      transform-origin: 0% 50%;
    }
  }
  
  @keyframes antZoomLeftOut {
    0% {
      transform: scale(1);
      transform-origin: 0% 50%;
    }
    100% {
      transform: scale(0.8);
      transform-origin: 0% 50%;
      opacity: 0;
    }
  }
  
  @keyframes antZoomRightIn {
    0% {
      transform: scale(0.8);
      transform-origin: 100% 50%;
      opacity: 0;
    }
    100% {
      transform: scale(1);
      transform-origin: 100% 50%;
    }
  }
  
  @keyframes antZoomRightOut {
    0% {
      transform: scale(1);
      transform-origin: 100% 50%;
    }
    100% {
      transform: scale(0.8);
      transform-origin: 100% 50%;
      opacity: 0;
    }
  }
  
  @keyframes antZoomDownIn {
    0% {
      transform: scale(0.8);
      transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      transform: scale(1);
      transform-origin: 50% 100%;
    }
  }
  
  @keyframes antZoomDownOut {
    0% {
      transform: scale(1);
      transform-origin: 50% 100%;
    }
    100% {
      transform: scale(0.8);
      transform-origin: 50% 100%;
      opacity: 0;
    }
  }