.spinner_container
  width: 100%
  margin-top: 10px
  display: flex
  flex-flow: column
  align-items: center

.lds_ring
  display: flex
  justify-content: center
  height: 64px
  width: 100%

.lds_ring div
  box-sizing: border-box
  display: block
  position: absolute
  width: 51px
  height: 51px
  margin: 6px
  border: 6px solid #5c84cf
  border-radius: 50%
  animation: lds_ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
  border-color: #5c84cf transparent transparent transparent

.lds_ring div:nth-child(1)
  animation-delay: -0.45s

.lds_ring div:nth-child(2)
  animation-delay: -0.3s

.lds_ring div:nth-child(3)
  animation-delay: -0.15s

p
  right: 0
  left: 0

@keyframes lds_ring
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
