.save_btn
  background: #265ecb
  border: 1px solid #265ecb
  color:#ffff
  border-radius: 20px
  font-size: 16px
  justify-content: center
  min-width: 90px
  padding: 6px
  &:hover
    color:#d7d7d7
      
.actived_courses
  display: flex
  color: #265ecb
  font-size: 16px
  margin: 12px auto
  align-items: center
  padding-left: 32px
  padding-right: 32px
  .button_container
    margin-left: auto

.course_container
  color: #143169
  margin: 30px auto
  width: 84%

@media only screen and (max-width: 730px)
  .course_container
    width: 90%
    font-size: 13px

.course_detail
  align-items: center
  background: #f9fafb
  border: 2px solid #ecf0f1
  border-radius: 8px 8px 0px 0px
  -moz-border-radius: 8px 8px 0px 0px
  -webkit-border-radius: 8px 8px 0px 0px
  //border: 0px solid #000000;
  display: flex
  height: 120px
  justify-content: space-around
  margin-top: 0px
  cursor: pointer

.course_detail_drop
  align-items: center
  background: #f9fafb
  border: 2px solid #ecf0f1
  border-radius: 8px 8px 8px 8px
  -moz-border-radius: 8px 8px 8px 8px
  -webkit-border-radius: 8px 8px 8px 8px
  //border: 0px solid #000000;
  display: flex
  height: 120px
  justify-content: space-around
  margin-top: 0px
  cursor: pointer    

.card_details 
  .card .cardBody
  //align-items: center
  background: #f9fafb
  //margin-bottom: 50px
  margin-left: 20px
  width: 80%
  
.container_exams
  align-items: center
  background: white
  border: 2px solid #ecf0f1
  //border-radius: 8px
  //display: flex
  height: 100%
  justify-content: space-around
  //margin-top: 12px
  border-radius: 0px 0px 8px 8px
  -moz-border-radius: 0px 0px 8px 8px
  -webkit-border-radius: 0px 0px 8px 8px
  border-top: none
  -webkit-transition: width 5s
  transition: width 5s
 

.hide_exams
  display: none

.row_text
  text-align: center
  margin-bottom: 10px
  margin-top: 0px

.group_name
  color: #265ecb
  font-size: 16px