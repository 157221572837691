main {
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro";
  font-size: 13px;
  line-height: 1.6;
  color: $colorText;
}

* {
  font-family: "Source Sans Pro";
  box-sizing: inherit;
}

ul,
ol {
  padding-left: 15px;
  margin-bottom: 0;
}

a {
  color: $color-blue;
  transition: all 0.3s;
  &:hover {
    text-decoration: none;
    color: $color-blue-hover;
  }
}

.fade-enter .sidebar__content {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-enter-active .sidebar__content {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit .sidebar__content {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-active .sidebar__content {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-enter-active .sidebar__content,
.fade-exit-active .sidebar__content {
  transition: opacity 500ms, transform 500ms;
}

//modal styles
.styles_modal__gNwvD {
  max-width: 1300px;
}

.rccs {
  .rccs__card--front,
  .rccs__card--back {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    border:0.5px solid rgba(0, 0, 0, 0.1)
  }
}

@media only screen and (max-width: 1300px) {
  .styles_modal__gNwvD {
    //width: 100%;
    //max-width: 100%;
    padding: 0px;
  }
}
