.container
  padding-top: 20px
  margin: 0 auto
  width: 90%

.title
  color: #143169
  font-size: 20px

.selector
  display: flex
  align-items: center
  justify-content: space-around
  width: 80%
  margin: 16px auto 32px
  border: 1px solid #cbd0d1
  border-radius: 40px
  height: 40px
  text-align: center
  select
    background: none
    border: none
    height: 100%
    text-align: center
    width: 90%

.course_detail
  margin: 0 auto

.course_row
  align-items: center
  display: flex
  justify-content: flex-start
  margin-top: 16px
  font-size: 20px
  span
    display: block
    text-align: center
    width: 20%
  p
    width: 80%

.course_done
  span
    color: #2ecc71
  p
    color: #000000

.course_fail
  span
    color: #f33030
  p
    color: #9B9B9B
