@import "../settings/variable.scss";

.wizard {
  background-color: white;

  .wizard__form {
    max-width: 610px;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 100px;
    padding: 0 25px;

    .wizard__toolbar {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.wizard__steps {
  display: flex;
}

.wizard__step {
  width: 100%;
  text-align: center;
  height: 55px;
  text-transform: uppercase;
  display: flex;
  transition: background 0.3s;
  padding-right: 4px;
  padding-left: 4px;

  //border: 1px solid #eff1f5;
  background: #fafbfe;

  p {
    font-weight: 700;
    margin: auto;
    font-size: 14px;
    transition: all 0.3s;
  }

  &.wizard__step--active {
    background: $color-blue-dash;
    border-color: $color-blue-dash;

    p {
      color: #ffffff;
    }
  }
  // &.wizard__step--active {
  //   background: $color-blue;
  //   border-color: $color-blue;

  //   p {
  //     color: #ffffff;
  //   }
  // }
}

.wizard__form-wrapper {
  display: flex;
  justify-content: center;
}

.wizard__title {
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
}

.wizard__description {
  color: $color-additional;
  margin: 0;
  max-width: 410px;
}

@media only screen and (max-width: 800px) {
  .wizard__step {
    width: 100%;
    text-align: center;
    height: 50px;
    text-transform: capitalize;
    display: flex;
    transition: background 0.3s;

    p {
      font-weight: 500;
      margin: auto;
      font-size: 11px;
      transition: all 0.3s;
    }

    &.wizard__step--active {
      background: $color-blue-dash;
      border-color: $color-blue-dash;

      p {
        color: #ffffff;
      }
    }
  }
}
